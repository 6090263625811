<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">
		<!-- <span>SUPPORT & SERVICE</span> -->
		<img src="../../assets/index/su.jpg" alt="">
	</div>
    <div class="title">
		The professional knowledge and experience of Orchid together with its proprietary tools are made available for customers to provide them with the necessary support to solve any problem related to Orchid products or solutions. Support is provided in the form of documentation, software and utilities, as well as useful and interesting information. Orchid offers a full array of service and support options to help you implement and maintain the most dependable automated data capture technology at the lowest cost of ownership available.
    </div>
    <div class="formBox">
      <div class="form">
        <div class="form_title">DOWNLOADS</div>
        <div class="form_title2">
          From this section you may download various useful resources related to
          Orchid products
        </div>
        <div class="form_main">
          <!-- 第一个选择框的内容 -->
          <div>
            <el-select
              v-model="checkedCategor"
              filterable
              placeholder="Search by category"
              class="select select2"
              @change="checkCategory"
            >
              <el-option
                v-for="item in categorList"
                :key="item.value"
                :label="item.value"
                :value="item"
              >
              </el-option>
            </el-select>
            <img src="@/assets/index/popUp.png" alt="" />
          </div>
          <!-- 第二个选择框的内容 -->
          <div>
            <el-select
              v-model="checkedSubclass"
              filterable
              placeholder="Search by subclass"
              class="select select2"
              @change="checkSubclass"
            >
              <el-option
                v-for="item in subclassList"
                :key="item.value"
                :label="item.value"
                :value="item"
              >
              </el-option>
            </el-select>
            <img src="@/assets/index/popUp.png" alt="" />
          </div>
        </div>
        <div class="form_submit" @click="search">SEARCH</div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api.js";
export default {
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "", title: "SUPPORT & SERVICE" },
    ];
    this.productOneTypeList();
  },
  data() {
    return {
      breadcrumbList: [],

      categorList: [],
      checkedCategor: "",
      categorId: "",

      //Search by subclass
      subclassList: [],
      checkedSubclass: "",
      subclassId: "",
    };
  },
  components: {
    Breadcrumb,
  },
  methods: {
    //Search by category
    checkCategory(item) {
      this.categorId = item.id;
      this.checkedCategor = item.value;
      this.subclassId = "";
      this.checkedSubclass = "";
      api.productTwoTypeList({ oneTypeId: item.id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.subclassList = [];
          data.data.forEach((item) => {
            this.subclassList.push({ value: item.name, id: item.id });
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    checkSubclass(item) {
      this.subclassId = item.id;
      this.checkedSubclass = item.value;
    },

    //搜索
    search() {
      this.$router.push({
        path: "/downloads",
        query: {
          categorId: this.categorId,
          subclassId: this.subclassId,
          checkedCategor: this.checkedCategor,
          checkedSubclass: this.checkedSubclass,
        },
      });
    },
    //获取产品一级分类
    productOneTypeList() {
      api.productOneTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.categorList = [];
          data.data.forEach((item) => {
            this.categorList.push({ value: item.name, id: item.id });
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  // background-image: url("../../assets/index/carousels.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  // height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  // line-height: 186px;
  text-align: center;
  position: relative;
  img{
	  max-width: 100%;
  }
  span{
	position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%,-50%);
  }
}
.formBox {
  background: #f0f2f7;
  padding-bottom: 53px;
}
.form {
  width: 1156px;
  margin: 0 auto;
  font-family: Arial;
  font-weight: normal;
  .form_title {
    font-size: 36px;
    color: #004a9c;
    text-align: center;
    padding: 44px 0 20px;
  }
  .form_title2 {
    font-size: 16px;
    color: #5a5b5e;
    text-align: center;
    margin-bottom: 46px;
  }
  .form_formName {
    font-size: 24px;
    color: #5a5b5e;
    text-align: center;
  }
  .form_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    .select {
      width: 263px;
      height: 45px;
      background: #ffffff;
      border: 1px solid #004a9c;
      margin: 0 12.5px;
      position: relative;

      font-size: 16px;
      font-family: Arial;
      font-weight: normal;
      color: #172f66;

      padding: 0 19px 0 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 12px;
        height: 6px;
      }
      > div {
        position: absolute;

        width: 100%;
        left: 0;
        top: 45px;
        ul {
          list-style: none;
          padding: 0;
          background: #ffffff;
          li {
            padding: 0px 10px;
            line-height: 45px;
            height: 45px;
            outline: 1px solid #004a9c;
          }
        }
      }
    }
    .select2 {
      width: 360px;
      padding: 0;
    }
    > div {
      position: relative;
      img {
        position: absolute;
        z-index: 11;
        right: 26px;
        top: 20px;
        width: 12px;
        height: 6px;
      }
    }
  }
  textarea {
    width: 1127px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #004a9c;
    padding: 14px;
  }
  .form_submit {
    width: 263px;
    height: 45px;
    background: #004a9c;
    font-size: 18px;
    text-align: center;
    line-height: 45px;
    color: #ffffff;

    margin: 43px auto 0;
  }
}
.title {
  margin: 50px auto 57px;
  width: 1136px;
  line-height: 24px;
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #5a5b5e;
}
</style>

<style scoped>
div /deep/ .el-input input {
  font-size: 16px;
  color: #000;
}
div /deep/ .el-input__inner {
  height: 45px;
  border: 0;
}
</style>

<style scoped>
div /deep/ .el-input__suffix-inner {
  display: none;
}
</style>